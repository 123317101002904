<template>
    <div>
        <!-- Use filterGroups directly, not localGroups -->
        <div v-for="(group, gIndex) in filterGroups" :key="gIndex" class="mb-3">
            <div v-if="group.name" class="fw-bold mb-1">{{ group.name }}</div>
            <span v-for="(filter, fIndex) in group.filters" :key="filter.key" class="badge rounded-pill me-2"
                :class="filter.active ? filter.activeClass : filter.inactiveClass"
                @click="toggleFilter(gIndex, fIndex)">
                {{ filter.label }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterGroups',
    props: {
        filterGroups: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        toggleFilter(gIndex, fIndex) {
            const group = this.filterGroups[gIndex];
            const filter = group.filters[fIndex];

            if (group.multi) {
                // multi-select => toggle
                filter.active = !filter.active;
            } else {
                // single-select => check if already active
                if (filter.active) {
                    filter.active = false;
                } else {
                    // deselect others, select this
                    group.filters.forEach(f => (f.active = false));
                    filter.active = true;
                }
            }

            // gather all active filters
            const allActive = [];
            this.filterGroups.forEach(g => {
                g.filters.forEach(f => {
                    if (f.active) {
                        allActive.push(f);
                    }
                });
            });

            // emit them
            this.$emit('filters-changed', allActive);
        }
    }
};
</script>

<style scoped>
.badge {
    cursor: pointer;
}
</style>