<template>
    <div class="container">
        <div v-if="loading">
            <!-- You could show a spinner or some placeholder here -->
        </div>
        <div v-else>
            <div v-if="realestate">
                <div role="button" class="small" @click="goBack()">
                    <i class="fa-solid fa-chevron-left m-2 mt-3"></i>Tagasi
                </div>
                <h3 class="my-3">{{ realestate.name }}</h3>

                <div class="accordion shadow mt-3" id="accordionProjects0">
                    <!-- Accordion for type_id = 0 -->
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseType0" aria-expanded="false"
                                aria-controls="collapseType0">
                                Objektikaardid
                            </button>
                        </h2>
                        <div id="collapseType0" class="accordion-collapse collapse" data-bs-parent="#accordionProjects0">
                            <div class="accordion-body">
                                <ul class="list-group list-group-flush" v-if="projectsType0.length > 0">
                                    <router-link v-for="project in projectsType0" :key="project.id"
                                        :to="`/projects/${project.id}`" class="list-group-item list-group-item-action">
                                        {{ project.name }}
                                    </router-link>
                                </ul>
                                <div v-else class="fst-italic small">Objektikaardid puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion shadow mt-3" id="accordionProjects1">
                    <!-- Accordion for type_id = 1 -->
                    <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseType1" aria-expanded="false"
                                aria-controls="collapseType1">
                                Paigalduskaardid
                            </button>
                        </h2>
                        <div id="collapseType1" class="accordion-collapse collapse" data-bs-parent="#accordionProjects1">
                            <div class="accordion-body">
                                <ul class="list-group list-group-flush" v-if="projectsType1.length > 0">
                                    <router-link v-for="project in projectsType1" :key="project.id"
                                        :to="`/projects/${project.id}`" class="list-group-item list-group-item-action">
                                        {{ project.name }}
                                    </router-link>
                                </ul>
                                <div v-else class="fst-italic small">Paigalduskaardid puuduvad</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col text-center">
                        <router-link :to="{ name: 'RealestateTasks', params: { realestateId: realestate.id }}">
                            <button class="btn btn-primary">Vaata Hoone töid</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <NotFound v-else :item="'hoone'" />
        </div>
    </div>
</template>

<script>
import axios from '@/services/axios';
import NotFound from '@/components/NotFound.vue';

export default {
    data() {
        return {
            realestate: null,
            loading: true
        };
    },
    components: {
        NotFound
    },
    computed: {
        // Projects with type_id = 0
        projectsType0() {
            if (!this.realestate) return [];
            return this.realestate.projects.filter(proj => proj.type_id == 0);
        },
        // Projects with type_id = 1
        projectsType1() {
            if (!this.realestate) return [];
            return this.realestate.projects.filter(proj => proj.type_id == 1);
        }
    },
    methods: {
        async fetchRealestate() {
            try {
                const realestateId = this.$route.params.realestateId;
                const response = await axios.get(`/realestates/${realestateId}`);
                this.realestate = response.data.data;
            } catch (error) {
                console.error('Error fetching realestate details:', error);
                // Optionally, handle the error (e.g., set an error message, redirect, etc.)
            } finally {
                this.loading = false;
            }
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
    },
    mounted() {
        this.fetchRealestate();
    }
};
</script>