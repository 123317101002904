<template>
    <div class="container">
        <h1 class="mt-3">{{ title }}</h1>

        <div class="input-group mb-3 rounded-pill">
            <input type="text" class="form-control" placeholder="Otsi töid" v-model="searchTerm"
                @keyup.enter="fetchTasks(1, searchTerm)">
            <span class="input-group-text bg-white" type="button" @click="fetchTasks(1, searchTerm)">
                <i class="fa fa-search"></i>
            </span>
        </div>

        <!-- Filter Groups -->
        <FilterGroups v-if="showFilters" :filterGroups="filterGroups" @filters-changed="onFiltersChanged" />

        <TaskList :tasks="tasks" />
        <ListPagination :currentPage="currentPage" :totalTasks="totalTasks" :limit="limit"
            @page-changed="(page) => fetchTasks(page, searchTerm)" />
    </div>
</template>

<script>
import axios from '@/services/axios';
import TaskList from '@/components/TaskList.vue';
import ListPagination from '@/components/ListPagination.vue';
import FilterGroups from '@/components/FilterGroups.vue';

export default {
    name: 'TasksListView',
    components: { TaskList, ListPagination, FilterGroups },
    props: {
        title: String,
        apiUrl: String,
        showFilters: {
            type: Boolean,
            default: false // show filters by default
        }
    },
    data() {
        return {
            tasks: [],
            currentPage: 1,
            limit: 10,
            totalTasks: 0,
            searchTerm: '',
            activeFilters: [],

            filterGroups: [
                {
                    name: '',  // optional group title
                    multi: false,      // can select multiple
                    filters: [
                        {
                            key: 'stage0',
                            label: 'Ootel',
                            active: false,
                            activeClass: 'bg-warning-subtle text-warning',
                            inactiveClass: 'text-bg-light',
                            params: { stage_id: '0', finished: '0' }
                        },
                        {
                            key: 'stage1',
                            label: 'Töös',
                            active: false,
                            activeClass: 'bg-info-subtle text-info',
                            inactiveClass: 'text-bg-light',
                            params: { stage_id: '1', finished: '0' }
                        },
                        {
                            key: 'stage2',
                            label: 'Vormistamine',
                            active: false,
                            activeClass: 'bg-success-subtle text-success',
                            inactiveClass: 'text-bg-light',
                            params: { stage_id: '2', finished: '0' }
                        },
                        {
                            key: 'finished',
                            label: 'Lõpetatud',
                            active: false,
                            activeClass: 'bg-success-subtle text-success',
                            inactiveClass: 'text-bg-light',
                            params: { finished: '1' }
                        },
                    ]
                },
                {
                    name: '',
                    multi: true, // only one can be selected in this group
                    filters: [
                        {
                            key: 'priority1',
                            label: 'Hooldus',
                            active: false,
                            activeClass: 'text-bg-dark',
                            inactiveClass: 'text-bg-light',
                            params: { priority_id: '1' }
                        },
                        {
                            key: 'priority2',
                            label: 'Rike',
                            active: false,
                            activeClass: 'text-bg-dark',
                            inactiveClass: 'text-bg-light',
                            params: { priority_id: '2' }
                        },
                        {
                            key: 'priority3',
                            label: 'Paigaldus',
                            active: false,
                            activeClass: 'text-bg-dark',
                            inactiveClass: 'text-bg-light',
                            params: { priority_id: '3' }
                        }
                    ]
                }
            ]
        };
    },
    mounted() {
        if (this.showFilters) {
            this.loadSelectedFilters();
        }
        this.fetchTasks();
    },
    methods: {
        onFiltersChanged(activeFilters) {
            this.activeFilters = activeFilters;
            // Re-fetch from page 1
            this.fetchTasks(1, this.searchTerm);
            this.saveSelectedFilters();
        },

        async fetchTasks(page = 1, searchTerm = '') {
            try {
                let url = `${this.apiUrl}&page=${page}&limit=${this.limit}&search=${encodeURIComponent(searchTerm)}`;

                if (this.showFilters) {
                    const mergedParams = this.mergeFilterParams(this.activeFilters);
                    // Append them
                    Object.keys(mergedParams).forEach(key => {
                        url += `&${key}=${encodeURIComponent(mergedParams[key])}`;
                    });
                }

                const response = await axios.get(url);
                this.tasks = response.data.data;
                this.totalTasks = response.data.total;
                this.currentPage = page;
            } catch (error) {
                console.error(`Error fetching tasks for ${this.title}:`, error);
            }
        },

        mergeFilterParams(filters) {
            const paramMap = {};
            filters.forEach(f => {
                Object.entries(f.params).forEach(([key, rawValue]) => {
                    // rawValue could be e.g. "1" or "1,2"
                    const values = rawValue.split(',');
                    if (!paramMap[key]) {
                        paramMap[key] = [];
                    }
                    paramMap[key].push(...values);
                });
            });

            // Remove duplicates, combine
            const merged = {};
            Object.keys(paramMap).forEach(key => {
                const uniqueValues = [...new Set(paramMap[key])];
                merged[key] = uniqueValues.join(',');
            });
            return merged;
        },
        saveSelectedFilters() {
            // We gather the keys of all active filters
            // Something like ["ootel","toos","lopetatud"] if those are active
            const activeKeys = this.activeFilters.map(f => f.key);
            localStorage.setItem('taskListFilterKeys', JSON.stringify(activeKeys));
        },
        // Load filterGroups from localStorage if present
        loadSelectedFilters() {
            const stored = localStorage.getItem('taskListFilterKeys');
            if (!stored) return;  // nothing saved yet

            try {
                const keysArray = JSON.parse(stored); // e.g. ["ootel","toos","lopetatud"]
                // Now we walk through each filter in each group
                // If filter.key is in keysArray => set active = true, else false
                this.filterGroups.forEach(group => {
                    group.filters.forEach(filter => {
                        filter.active = keysArray.includes(filter.key);
                    });
                });
            } catch (err) {
                console.warn('Could not parse saved filter keys:', err);
            }
            this.collectActiveFilters();
        },
        collectActiveFilters() {
            const active = [];
            this.filterGroups.forEach(group => {
                group.filters.forEach(filter => {
                    if (filter.active) {
                        active.push(filter);
                    }
                });
            });
            this.activeFilters = active;
        },
    }
};
</script>