<template>
    <TasksListComponent title="Tööd" :apiUrl="apiUrl" :showFilters="true" />
</template>

<script>
import TasksListComponent from '@/components/TasksListComponent.vue';

export default {
    name: 'TasksListView',
    components: {
        TasksListComponent
    },
    computed: {
        apiUrl() {
            const params = new URLSearchParams({
                sort_by: 'estimated_date',
                sort_order: 'desc'
            });

            const realestateId = this.$route.params.realestateId;
            const projectId = this.$route.params.projectId;
            const objectId = this.$route.params.objectId;

            if (realestateId) {
                params.append('realestate_id', realestateId);
            }
            if (projectId) {
                params.append('project_id', projectId);
            }
            if (objectId) {
                params.append('object_id', objectId);
            }

            return `tasks/?${params.toString()}`;
        },
    }

};
</script>