<template>
    <div class="card shadow border-0 mb-3">
        <div class="card-body">
            <span :class="stageClass(task.stage_id, task.finished)" class="badge rounded-pill me-1">{{
                stageText(task.stage_id, task.finished)
            }}</span>
            <span class="badge rounded-pill text-bg-light me-1">{{ task.priority_name }}</span>
            <span class="badge rounded-pill text-bg-light me-1">{{ task.id }}</span>
            <span v-if="task.agreed" class="badge rounded-pill text-bg-warning me-1">Kokkulepitud</span>
            <span v-if="task.is_critical" class="badge rounded-pill text-bg-danger">Kriitiline</span>
            <h5 class="mt-3">{{ task.name }}</h5>
            <div class="small mb-1" v-if="task.project_address"><i class="fa-solid fa-location-dot fa-fw me-2"></i>{{ task.project_address }}</div>
            <div class="small mb-1" v-if="task.project_id"><router-link :to="`/projects/${task.project_id}`" class="text-decoration-none"><i class="fa-solid fa-file-invoice fa-fw me-2"></i>{{ task.project_name }}</router-link></div>
            <div class="small mb-1" v-if="task.realestate_id"><router-link :to="`/realestates/${task.realestate_id}`" class="text-decoration-none"><i class="fa-regular fa-building fa-fw me-2"></i>{{ task.realestate_name }}</router-link></div>
            <div class="small" v-if="task.object_id"><router-link :to="`/objects/${task.object_id}`" class="text-decoration-none"><i class="fa-solid fa-walkie-talkie fa-fw me-2"></i>{{ task.object_name }}</router-link></div>
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col fw-medium">Planeeritud:</div>
                <div class="col text-end">
                    <span v-if="editable || task.estimated_date" role="button" class="badge rounded-pill bg-success-subtle text-success fw-medium py-2 px-3"
                        @click="editable && task.finished == 0 ? openDatePicker(task) : null">
                        {{ task.timed_due_date ? (task.start_date ? formatTimestamp(task.start_date, true) : '') : (task.estimated_date ? formatTimestamp(task.estimated_date, false) : '') }}
                        <i class="fa-solid fa-pencil" v-if="editable"></i>
                    </span>
                </div>
            </div>
            <div class="row align-items-center mt-1">
                <div class="col fw-medium">Tähtaeg:</div>
                <div class="col text-end">
                    <span class="badge rounded-pill text-bg-light fw-medium py-2 px-3">
                        {{ task.due_date ? formatTimestamp(task.due_date, false) : '' }}
                    </span>
                </div>
            </div>
            <div v-if="task.finished" class="row align-items-center mt-1">
                <div class="col fw-medium">Lõpetatud:</div>
                <div class="col text-end">
                    <span class="badge rounded-pill text-bg-light fw-medium py-2 px-3">
                        {{ task.finished_on ? formatTimestamp(task.finished_on, true) : '' }}
                    </span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col fw-bolder"><small>Kliendi kontakt:</small></div>
            </div>
            <div class="row">
                <div class="col small">{{ task.feedback }}</div>
            </div>
        </div>
        <div class="card-body" v-if="task.reminders">
            <div class="row">
                <div class="col">
                    <span class="badge rounded-pill bg-danger-subtle text-danger fw-normal w-100 text-start">
                        <img :src="require('@/assets/ico-exclamation-mark.svg')">
                        <span class="align-middle"> Objektiga on seotud aktiivne meeldetuletus.</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showDatePicker" class="date-picker-modal bg-light">
        <span @click="closeDatePicker" role="button"><small><i
                    class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h5 class="mt-3">Muuda planeeritud kuupäeva</h5>

        <div v-if="!unTimedDueDate" class="mt-3"><small>Kuupäev</small>
            <VueDatePicker v-model="selectedDate" format="dd.MM.yyyy" date-picker auto-apply input-class-name="bg-light"
                locale="et" :clearable="false" required />
        </div>

        <div v-if="!unTimedDueDate" class="mt-3"><small>Kellaaeg</small>
            <VueDatePicker v-model="selectedTime" time-picker input-class-name="bg-light" locale="et" cancelText="Loobu"
                selectText="Vali" :clearable="false" required />
        </div>

        <div class="mt-3"><small>Kuupäev</small>
            <VueDatePicker v-model="selectedEndDate" format="dd.MM.yyyy" date-picker auto-apply
                input-class-name="bg-light" locale="et" :clearable="false" required />
        </div>

        <div v-if="!unTimedDueDate" class="mt-3"><small>Kellaaeg</small>
            <VueDatePicker v-model="selectedEndTime" time-picker input-class-name="bg-light" locale="et"
                cancelText="Loobu" selectText="Vali" :clearable="false" required />
        </div>

        <div class="row mt-3">
            <div class="col">
                <div class="mb-3 form-check">
                    <input type="checkbox" v-model="unTimedDueDate" class="form-check-input" id="timedCheckbox">
                    <label class="form-check-label" for="timedCheckbox">Ilma kellaajata</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="mb-3 form-check">
                    <input type="checkbox" v-model="agreed" class="form-check-input" id="agreedCheckbox">
                    <label class="form-check-label" for="agreedCheckbox">Kokkulepitud</label>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col"><button @click="closeDatePicker"
                    class="btn border-1 border-primary text-primary w-100">Loobu</button></div>
            <div class="col"><button @click="saveDateTime" class="btn btn-primary w-100">Salvesta</button></div>
        </div>
    </div>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { useFormatTimestamp } from '@/composables/useFormatTimestamp';
import axios from '@/services/axios';

export default {
    name: 'TaskComponent',
    components: {
        VueDatePicker
    },
    props: {
        task: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDatePicker: false,
            selectedDate: {
                day: new Date().getDay(),
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            },
            selectedTime: {
                hours: new Date().getHours(),
                minutes: new Date().getMinutes()
            },
            selectedEndDate: {
                day: new Date().getDay(),
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            },
            selectedEndTime: {
                hours: new Date().getHours(),
                minutes: new Date().getMinutes()
            },
            unTimedDueDate: false,
            agreed: this.task.agreed,
            currentTaskId: null,
        };
    },
    setup() {
        const { formatTimestamp } = useFormatTimestamp();
        return { formatTimestamp };
    },
    emits: ['update-estimated-date'],
    methods: {
        stageClass(stageId, finished) {
            if (finished) {
                return 'bg-success-subtle text-success';
            }
            switch (stageId) {
                case 1: return 'bg-info-subtle text-info';
                case 2: return 'bg-success-subtle text-success';
                default: return 'bg-warning-subtle text-warning';
            }
        },
        stageText(stageId, finished) {
            if (finished) {
                return 'Lõpetatud';
            }
            switch (stageId) {
                case 1: return 'Töös';
                case 2: return 'Vormistamine';
                default: return 'Ootel';
            }
        },
        openDatePicker(task) {
            this.currentTaskId = task.id;
            this.unTimedDueDate = !task.timed_due_date;

            if (!this.unTimedDueDate && task.start_date) {
                const taskDateTime = new Date(task.start_date);
                this.selectedDate = taskDateTime;

                this.selectedTime = {
                    hours: taskDateTime.getHours(),
                    minutes: taskDateTime.getMinutes()
                };
            } else if (this.unTimedDueDate && task.estimated_date) {
                const taskDateTime = new Date(task.estimated_date);
                this.selectedDate = taskDateTime;

                this.selectedTime = { hours: 9, minutes: 0 };
            } else {
                this.selectedDate = new Date();
                this.selectedTime = { hours: 9, minutes: 0 };
            }

            if (!this.unTimedDueDate && task.estimated_date) {
                const taskEndDateTime = new Date(task.estimated_date);
                this.selectedEndDate = taskEndDateTime;

                this.selectedEndTime = {
                    hours: taskEndDateTime.getHours(),
                    minutes: taskEndDateTime.getMinutes()
                };
            } else if (this.unTimedDueDate && task.estimated_date) {
                const taskEndDateTime = new Date(task.estimated_date);
                this.selectedEndDate = taskEndDateTime;
                this.selectedEndTime = { hours: 10, minutes: 0 };
            } else {
                this.selectedEndDate = new Date();
                this.selectedEndTime = { hours: 9, minutes: 0 };
            }

            this.showDatePicker = true;
        },
        closeDatePicker() {
            this.showDatePicker = false;
        },
        saveDateTime() {
            const combinedDateTime = this.combineDateAndTime();
            const combinedEndDateTime = this.combineEndDateAndTime();
            if (!this.unTimedDueDate && combinedDateTime > combinedEndDateTime) {
                this.$root.$refs.globalAlert.showAlert('Invalid dates', 'danger');
                console.error('Start date and time must be earlier than or equal to end date and time.');
                return;
            }
            this.updateTaskEstimatedDate(combinedDateTime, combinedEndDateTime, this.unTimedDueDate, this.agreed);
            this.showDatePicker = false;
        },
        combineDateAndTime() {
            const date = new Date(this.selectedDate);
            date.setHours(this.selectedTime.hours, this.selectedTime.minutes);
            return date;
        },
        combineEndDateAndTime() {
            const date = new Date(this.selectedEndDate);
            date.setHours(this.selectedEndTime.hours, this.selectedEndTime.minutes);
            return date;
        },
        async updateTaskEstimatedDate(dateTime, endDateTime, unTimedDueDate, agreed) {
            try {
                const formattedDate = `${dateTime.getFullYear()}-${(dateTime.getMonth() + 1).toString().padStart(2, '0')}-${dateTime.getDate().toString().padStart(2, '0')}`;
                const formattedTime = `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}:00`;

                const formattedDateTime = unTimedDueDate ? null : formattedDate + ' ' + formattedTime;

                const formattedEndDate = `${endDateTime.getFullYear()}-${(endDateTime.getMonth() + 1).toString().padStart(2, '0')}-${endDateTime.getDate().toString().padStart(2, '0')}`;
                const formattedEndTime = `${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}:00`;

                const formattedEndDateTime = formattedEndDate + (unTimedDueDate ? (' 00:00:00') : (' ' + formattedEndTime));

                const response = await axios.patch(`tasks/${this.currentTaskId}`, { start_date: formattedDateTime, estimated_date: formattedEndDateTime, timed_due_date: !unTimedDueDate, agreed: agreed });
                if (response.status === 200) {
                    this.$emit('update-estimated-date', { taskId: this.currentTaskId, newDate: formattedDateTime, newEndDate: formattedEndDateTime, timed_due_date: !unTimedDueDate, agreed: agreed });
                }
            } catch (error) {
                console.error('Error updating task estimated date:', error);
            }
        },
    }
};
</script>

<style scoped>
.date-picker-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    z-index: 1031;
}
</style>
